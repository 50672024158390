.vertical-separator-30px{
    height: 30px;
}

.vertical-separator-20px{
    height: 20px;
}

.vertical-separator-10px{
    height: 10px;
}

.vertical-separator-5px{
    height: 5px;
}

.badge-info{
    background-color: #17a2b8 !important;
}

