body{
    -webkit-print-color-adjust: exact !important;
}
.LabelsContainer{
    width: 100%;
    height: 10.3cm;
    -webkit-print-color-adjust: exact !important;
}

.LabelsSheet{
    box-sizing: border-box;
    /* margin: 0.5cm; */
    /* margin-bottom: 15cm; */
    /* margin-top: 0.5cm; */
}

.LabelCard{
    width: 6.75cm;
    height: 100%;
    border-bottom: solid 1px #000;
    border-right: solid 1px #000;
    float: left;
    box-sizing: border-box;
    font-family: CircularStd;
    font-weight: 400;
    color: #FFF;
}

.NumberCard{
    width: 100%;
    font-weight: 400;
    color: #FFF;
    font-size: 13rem;
    text-align: center;
    height: 5.8;
}

.CardReverse{
    width:100%;
    /* height: 2.3cm; */
}

.CardInfo{
    width: 100%;
    height: 2.5cm;
    box-sizing: border-box;
    padding: 15px 15px 0 15px;
}

.text-purple{
    color: #9368E9;
}

.CardName{
    font-weight: 700;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.CardText{
    font-size: 1.3rem;
}

.LabelsContainer.Upper{
    margin-top: 0.5cm;
    background: rgb(255,222,142);
    background: linear-gradient(45deg, rgba(255,222,142,1) 0%, rgba(238,180,145,1) 20%, rgba(230,143,152,1) 40%, rgba(196,226,186,1) 60%, rgba(133,205,185,1) 80%, rgba(86,197,206,1) 100%);
}

.LabelsContainer.Lower{
    background: rgb(255,222,142);
    background: linear-gradient(223deg, rgba(255,222,142,1) 0%, rgba(238,180,145,1) 20%, rgba(230,143,152,1) 40%, rgba(196,226,186,1) 60%, rgba(133,205,185,1) 80%, rgba(86,197,206,1) 100%);
}

.PrintLabel{
    /* border: solid 1px #000; */
}

.CardReverse{
    text-align: center;
    /* -webkit-transform:rotate(-180deg); */
    color: #FFF;
}

.CardReverse img{
    margin: 20px auto;
    display: block;
    width: 100px;
    margin-bottom: 10px;
}

.CardReverse span{
    display: inline-block;
    position: relative;
    left: -5px;
}

.title-empty-2{
    height: 1.4em;
}

@media print{

    .wrapper{
        height: auto !important;
    }
    
    .backToPlan{
        display: none !important;
    }
    
    .sidebar{
        display: none !important;
    }
    footer{
        display: none !important;
    }
    .main-panel{
        width: 100% !important;
    }
    .LabelsContainer{
        /* width: 37cm; */
        width: 27cm;
        /* margin: 0 0.5cm; */
        margin: 0 0.5cm;
        height: 10.3cm;
        color: #FFF !important;
    }
    .NumberCard{
        color: #FFF !important;
    }

    .LabelCard{
        color: #FFF !important;
        /* width: 25%; */
        width: 6.75cm;
    }
    .CardReverse{
        color: #FFF !important;
    }

    .CardName{
        color: #FFF !important;
    }
    .CardInfo{
        color: #FFF !important;
    }

    .CardText{
        color: #FFF !important;
    }
    .LabelsContainer.Lower {
        background: linear-gradient(223deg, rgba(255,222,142,1) 0%, rgba(238,180,145,1) 20%, rgba(230,143,152,1) 40%, rgba(196,226,186,1) 60%, rgba(133,205,185,1) 80%, rgba(86,197,206,1) 100%) !important;
        -webkit-print-color-adjust: exact !important; 
        page-break-after: always;
    }

    .LabelsContainer.Upper {
        background: linear-gradient(45deg, rgba(255,222,142,1) 0%, rgba(238,180,145,1) 20%, rgba(230,143,152,1) 40%, rgba(196,226,186,1) 60%, rgba(133,205,185,1) 80%, rgba(86,197,206,1) 100%) !important;
        -webkit-print-color-adjust: exact !important; 
    }
}