.newdiagnostic-container{
    margin-top: 15px;
}

.diagnosticbox-container{
    padding-top: 20px;
}

.diagnosticbox-title{
    padding-bottom: 20px;
}

@media (min-width: 768px) { 
    .diagnostic-button-container{
        padding: 10px 0px;
    }
}

.error{
    color:#FF9191;
}

.error-label{
    font-size: 1.4rem !important;
}