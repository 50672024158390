label{
    font-size: 2rem !important;
    font-weight: 400 !important;
}

.vertical-separator-10px{
    padding-top: 10px;
}

.vertical-separator-30px{
padding-top: 30px;
}

.vertical-separator-5px{
padding-top: 5px;
}

.vertical-separator-50px{
padding-top: 50px;
}

.vertical-separator-20px{
padding-top: 20px;
}

.vertical-separator-40px{
padding-top: 40px;
}