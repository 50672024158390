.moons-button-primary,
.moons-button-primary:focus
{
    display: inline-block;
    width: 100%;
    border-radius: 40px;
    border: 1.3px solid #ABD1F9;
    padding: 10px;
    text-decoration: none;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -.3px;
    text-align: center;
    color: white;
    outline: none;
    font-size: 1.5rem;
    background-color: #ABD1F9;
}

.moons-button-primary:hover,
.moons-button-primary:visited,
.moons-button-primary:active
{
  color: #fff;
  background-color: #ABD1F9;
  outline:none!important;
  box-shadow: none;
}