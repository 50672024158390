.nav-container{
    margin-bottom: 100px;
}

.notification-container{
    width: 200px;
}

.panel{
    margin-bottom: 5px !important;
}
.notification-cart-title-container{
    padding: 10px;
}

.notification-cart-titleSeparator{
    padding: 0 10px;
}

.notification-cart-title{
    font-weight: bolder;
}

.notification-cart-subTitle{
    font-style: italic;
}

@media print{
    .notification-icon{
        display: none !important;
    }

    .nav-global-container{
        display: none !important;
    }
    
    .notification-container{
        display: none !important;        
    }
    
    .panel{
        display: none !important;        
    }
    
    .notification-cart-title-container{
        display: none !important;        
    }
    
    .notification-cart-titleSeparator{
        display: none !important;        
    }
    
    .notification-cart-title{
        display: none !important;        
    }
    
    .notification-cart-subTitle{
        display: none !important;        
    }
}