.Preview{
    margin: 10px;
    width: 150px;
}

.teen{
  background-color: orange;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.AgeSpace{
  margin-bottom: 20px;
}

.adult{
  background-color: green;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.align-center{
  text-align: center;
}

a.White,
a.White:hover,
a.White:active
a.White:visited{
    color: #FFF !important;
}

a.fill-button{
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}

a.ColorLink,
a.ColorLink:hover,
a.ColorLink:active
a.ColorLink:visited{
    color: #23CCEF;
}

textarea{
    resize: none;
    height: 200px !important;
}

.loadingScreen{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0, 0.5);
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .margin-top{
    margin: 20px;
  }

  .breakLink{
    word-break: break-word;
  }

  .Capitalize{
    text-transform: capitalize;
  }

  .uploadMessage{
    color: #FFF;
    font-size: 1.5rem;
  }

  .emoticon{
    font-size: 4rem;
  }

  .title-empty{
    height: 0em;
  }

  .input-margin-bottom{
    margin-bottom: 10px;
  }

  .align-center{
    text-align: center;
  }