.notification-center-container{
    z-index: 100;
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-box-shadow: 10px 10px 34px 0px rgba(153,153,153,0.74) !important;
    -moz-box-shadow: 10px 10px 34px 0px rgba(153,153,153,0.74) !important;
    box-shadow: 10px 10px 34px 0px rgba(153,153,153,0.74) !important;
    position: absolute;
    right: 0;
}